import React from 'react';
import Layout from "../components/Layout";
import {defineMessages, useIntl} from "react-intl";
import woman from "../assets/images/accounting/woman.svg";
import longList from '../assets/images/accounting/long-list.svg';
import chart from "../assets/images/accounting/chart.svg";
import chartIcon from "../assets/images/accounting/chart-icon.svg";
import arrow from "../assets/images/accounting/main-arrow.svg";
import shortArrow from "../assets/images/accounting/short-arrow.svg";
import middleArrow from "../assets/images/accounting/middle-arrow.svg";
import invoiceArrow from "../assets/images/accounting/invoices-arrow.svg";
import invoicesBoard from "../assets/images/accounting/invoices-board.svg";
import longLine from "../assets/images/long-line2.svg";
import billingDetails from "../assets/images/accounting/billing-details.svg";
import middleLine from "../assets/images/line-bottom-middle.svg";
import accountantTools from "../assets/images/accounting/accountant-tools.svg";
import accountantTools2 from "../assets/images/accounting/accountant-tools2.svg";
import generateButton from "../assets/images/accounting/generate-button.svg";
import printer from "../assets/images/accounting/printer.svg";
import reportBoard from "../assets/images/accounting/report-board.svg";
import notes from "../assets/images/accounting/notes.svg";
import monitor from "../assets/images/accounting/monitor.svg";
import zigzagLine from "../assets/images/scrumboard/zigzag-line.svg";
import manager from "../assets/images/accounting/manager.svg";
import usersChart from "../assets/images/accounting/users-chart.svg";
import tasksChart from "../assets/images/accounting/tasks-chart.svg";
import timelogChart from "../assets/images/accounting/timelog-chart.svg";
import iconsGroup from "../assets/images/accounting/icons-group.svg";
import chart2 from "../assets/images/accounting/chart2.svg";
import Img from "../components/Img";

const intro = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Accounting'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Manage processes quickly'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Easily manage time cards and expenses, quickly turn them into invoices, send them to your clients, and get paid online. \n' +
            'Scrumship automatically pulls the billable time into invoices.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Make payments easier'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Stay on top of billing and make payments easier for clients. Track your organization\'s financial and performance history.'
    },
    woman: {
        id: 'Alt.Text',
        defaultMessage: 'woman happy using Scrumship finance management app'
    }
});

const invoices = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Invoices / Expenses / Payrolls'
    },
    headerPart1: {
        id: 'Header.H1.Text',
        defaultMessage: 'Invoices'
    },
    headerPart2: {
        id: 'Header.H1.Text',
        defaultMessage: 'Expenses'
    },
    headerPart3: {
        id: 'Header.H1.Text',
        defaultMessage: 'Payrolls'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'All invoicing and payments in one place'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship brings together all invoicing and payments in a single view. We provide you with a complete panel to manage your project finances. Multiple views give the ability to view content in the format that suits you best. Attach necessary documents, send invoices via email right from the site, organize expenses by types, generate payrolls for contracted employees and B2B partners.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Billing and invoicing'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship allows you to differentiate documents between Corresponding date, Sale date, Issue date and Payment date to fit your preferences.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'Just one click'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'Generate invoices automatically. One button will calculate invoices and timecards based on the timelog.\n' + 'Create accurate invoices and payrolls with minimal effort.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'invoices board in Scrumship finance management software'
    },
    board2: {
        id: 'Alt.Text',
        defaultMessage: 'billing details in Scrumship accounting software'
    }
});

const yearlyReport = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Yearly Report'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Visual financial insights'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship Yearly Report helps you gain clear visual financial insights to run your business effectively.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Income vs expenses report'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'We present an Income vs Expenses report with detailed information about inflows and spendings in your project account. This report gives you a view of whether or not you’re bringing in more than you’re spending.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'financial report board in Scrumship business accounting software'
    },
    woman: {
        id: 'Alt.Text',
        defaultMessage: 'accountant looking at balance report'
    },
    monitor: {
        id: 'Alt.Text',
        defaultMessage: 'monitor'
    },
});

const balanceReport = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Current Balance Report'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Billing data analysis'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship lets you view your Current Balance usage costs at a glance. Several different reports are available for your billing data analysis needs.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Can track costs'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'In the Report view we display three charts to give a quick overview for Project Owners on specific costs in the current month. Your Clients can track their costs generated in Project within the collaboration. '
    },
    advice1H3: {
        id: 'Header.Text',
        defaultMessage: 'users'
    },
    advice1H4: {
        id: 'Header.Text',
        defaultMessage: 'Showing cost distribution for each employee'
    },
    advice2H3: {
        id: 'Header.Text',
        defaultMessage: 'tasks'
    },
    advice2H4: {
        id: 'Header.Text',
        defaultMessage: 'We can find how much individual task cost us so far'
    },
    advice3H3: {
        id: 'Header.Text',
        defaultMessage: 'timelog type'
    },
    advice3H4: {
        id: 'Header.Text',
        defaultMessage: 'We can find how much each activity cost us so we can optimize our development process'
    },
    userChart: {
        id: 'Alt.Text',
        defaultMessage: 'user chart in Scrumship - financial app'
    },
    tasksChart: {
        id: 'Alt.Text',
        defaultMessage: 'tasks chart in Scrumship - finance management app'
    },
    timelogChart: {
        id: 'Alt.Text',
        defaultMessage: 'timelog chart in Scrumship - finance management software'
    },
});

const globalAlts = defineMessages({
    list: {
        id: 'Alt.Text',
        defaultMessage: 'expenses list'
    },
    tools: {
        id: 'Alt.Text',
        defaultMessage: 'accountant tools'
    },
    printer: {
        id: 'Alt.Text',
        defaultMessage: 'printer'
    },
    icon: {
        id: 'Alt.Text',
        defaultMessage: 'invoices'
    },
});

const Accounting = ({location}) => {
    const {formatMessage} = useIntl();
    return (
        <Layout pageName='accounting-subpage' location={location}>
            <section className='intro'>
                <div className='container'>
                    <div className='section-one'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(intro.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <div className='title title-no-line'><h3>{formatMessage(intro.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(intro.protip1Text)}</p>
                            </div>
                            <div className='arrow'>
                                <Img src={arrow} alt=""/>
                            </div>
                        </div>
                        <div className='woman'>
                            <div className='image-container full-width'>
                                <Img src={woman} alt={formatMessage(intro.woman)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(intro.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(intro.protip2Text)}</p>
                            </div>
                            <div className='short-arrow'>
                                <Img src={shortArrow} alt=""/>
                            </div>
                        </div>
                        <div className='long-list'>
                            <div className='image-container full-width'>
                                <Img src={longList} alt={formatMessage(globalAlts.list)}/>
                            </div>
                        </div>
                        <div className='chart-img'>
                            <div className='image-container full-width'>
                                <Img src={chart} alt=""/>
                            </div>
                        </div>
                        <div className='chart-icon'>
                            <div className='image-container full-width'>
                                <Img src={chartIcon} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='invoices'>
                <div className='container'>
                    <div className='section-two'>
                        <div className='label mobile'>
                            <h1 className='text-decoration-label'>{formatMessage(invoices.headerPart1)} / {formatMessage(invoices.headerPart2)}</h1>
                        </div>
                        <div className='label desktop'>
                            <h1 className='text-decoration-label'>{formatMessage(invoices.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title title-no-line'><h3>{formatMessage(invoices.protip1Header)}</h3>
                                </div>
                                <p className='protip-black'>{formatMessage(invoices.protip1Text)}</p>
                            </div>
                            <div className='arrow'>
                                <Img src={invoiceArrow} alt=""/>
                            </div>
                        </div>
                        <div className='invoices-board'>
                            <div className='image-container full-width'>
                                <Img src={invoicesBoard} alt={formatMessage(invoices.board)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper1'>
                            <div className='advice-container advice1'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(invoices.advice1Header)}</h4>
                                    <p>{formatMessage(invoices.advice1Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice1a'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-advice1b'>
                                <div className='image-container full-width'>
                                    <Img src={longLine} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='billing-details'>
                            <div className='image-container full-width'>
                                <Img src={billingDetails} alt={formatMessage(invoices.board2)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper2'>
                            <div className='advice-container advice2'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(invoices.advice2Header)}</h4>
                                    <p>{formatMessage(invoices.advice2Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice2'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='accountant-tools'>
                            <div className='image-container full-width'>
                                <Img src={accountantTools} alt={formatMessage(globalAlts.tools)}/>
                            </div>
                        </div>
                        <div className='accountant-tools2'>
                            <div className='image-container full-width'>
                                <Img src={accountantTools2} alt={formatMessage(globalAlts.tools)}/>
                            </div>
                        </div>
                        <div className='generate-button'>
                            <div className='image-container full-width'>
                                <Img src={generateButton} alt=""/>
                            </div>
                        </div>
                        <div className='printer'>
                            <div className='image-container full-width'>
                                <Img src={printer} alt={formatMessage(globalAlts.printer)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='yearly-report'>
                <div className='container'>
                    <div className='section-three'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(yearlyReport.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title title-no-line'>
                                    <h3>{formatMessage(yearlyReport.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(yearlyReport.protip1Text)}</p>
                            </div>
                            <div className='arrow'>
                                <Img src={middleArrow} alt=''/>
                            </div>
                        </div>
                        <div className='report-board'>
                            <div className='image-container full-width'>
                                <Img src={reportBoard} alt={formatMessage(yearlyReport.board)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(yearlyReport.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(yearlyReport.protip2Text)}</p>
                            </div>
                            <div className='image-container full-width mt-xs'>
                                <Img src={zigzagLine} alt=""/>
                            </div>
                        </div>
                        <div className='notes'>
                            <div className='image-container full-width'>
                                <Img src={notes} alt=""/>
                            </div>
                        </div>
                        <div className='manager'>
                            <div className='image-container full-width'>
                                <Img src={manager} alt={formatMessage(yearlyReport.woman)}/>
                            </div>
                        </div>
                        <div className='monitor'>
                            <div className='image-container full-width'>
                                <Img src={monitor} alt={formatMessage(yearlyReport.monitor)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='balance-report'>
                <div className='container'>
                    <div className='section-fourth'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(balanceReport.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(balanceReport.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(balanceReport.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='chart-wrapper'>
                            <div className='chart chart1'>
                                <h3>{formatMessage(balanceReport.advice1H3)}</h3>
                                <span className='description'>{formatMessage(balanceReport.advice1H4)}</span>
                                <div className='image-container full-width'>
                                    <Img src={usersChart} alt={formatMessage(balanceReport.userChart)}/>
                                </div>
                            </div>
                            <div className='chart chart2'>
                                <h3>{formatMessage(balanceReport.advice2H3)}</h3>
                                <span className='description'>{formatMessage(balanceReport.advice2H4)}</span>
                                <div className='image-container full-width'>
                                    <Img src={tasksChart} alt={formatMessage(balanceReport.tasksChart)}/>
                                </div>
                            </div>
                            <div className='chart chart3'>
                                <h3>{formatMessage(balanceReport.advice3H3)}</h3>
                                <span className='description'>{formatMessage(balanceReport.advice3H4)}</span>
                                <div className='image-container full-width'>
                                    <Img src={timelogChart} alt={formatMessage(balanceReport.timelogChart)}/>
                                </div>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(balanceReport.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(balanceReport.protip2Text)}</p>
                            </div>
                            <div className='image-container full-width mt-xs'>
                                <Img src={zigzagLine} alt=""/>
                            </div>
                        </div>
                        <div className='icons'>
                            <div className='image-container full-width'>
                                <Img src={iconsGroup} alt={formatMessage(globalAlts.icon)}/>
                            </div>
                        </div>
                        <div className='chart-img'>
                            <div className='image-container full-width'>
                                <Img src={chart2} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default Accounting;

